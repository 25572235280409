import { Store } from "vuex";
import { IState } from "@/store/types/IState";
import i18n from "@/i18n";

export class TrackingMapVisibilityService {
  private store: Store<IState>;

  constructor(store: Store<IState>) {
    this.store = store;
  }

  showTrackingMap(): boolean {
    if (
      !this.store.state.order.deliveryDate ||
      !this.store.state.service.actualInformationTo
    ) {
      return false;
    }

    const deliveryDate = new Date(this.store.state.order.deliveryDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const isDeliveryToday =
      deliveryDate.toDateString() === today.toDateString();

    return (
      isDeliveryToday &&
      this.store.state.service.actualInformationTo.gpsLatitude !== undefined &&
      this.store.state.service.actualInformationTo.gpsLongitude !== undefined &&
      this.store.state.service.actualInformationTo.remainingOrders !== undefined
    );
  }

  updateDataForTrackingMap() {
    if (this.showTrackingMap()) {
      if (!this.store.state.service.stateHistoryList) {
        this.store.state.service.stateHistoryList = [];
      }

      this.store.state.service.stateHistoryList.push({
        creationTime: this.getFormattedDeliveryDate(),
        orderStateId: 19,
        orderState: "UnterwegsZumKunden"
      });

      this.store.commit("SET_ORDER_STATE_DESCRIPTION", "Auftrag unterwegs");

      this.store.commit(
        "SET_NOTIFICATION_TEXT",
        i18n.t("notificationBox.description.deliveryEnRoute") as string
      );
    }
  }

  getFormattedDeliveryDate(): string {
    const deliveryDate = this.store.state.order.deliveryDate;
    if (!deliveryDate) {
      return "";
    }
    const formattedDeliveryDate = new Date(deliveryDate)
      .toISOString()
      .split("T")[0];
    return formattedDeliveryDate;
  }
}
