import { apiService } from "@/services/ApiService";
import { TrackingMapData } from "./types/TrackingMapData";

export class TrackingMapService {
  public async getTrackingMapData(internalOrderId: string) {
    return apiService.get<TrackingMapData>(
      `SelfScheduling/TourOrderDetail/${internalOrderId}`,
      {},
      "get_tracking_map_data"
    );
  }
}

export const trackingMapService = new TrackingMapService();
